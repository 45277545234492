<template>
	<div class="main-contents">
            <div class="mem_container">
                <div class="main-box">
                    <div class="mem_contents_header">
                        <div class="title"><span class="mem_info"></span><p>회원가입/{{title}}</p></div>
                        <div class="reg-step"><img src="/images/mem/process_bar_05.png" alt="절차"/></div>
                    </div>
                    <div class="mem_contents_body">
                        <div class="info-box">
                            <div class="phone-img">
								<div class="phone-info completion " v-if="title == '기술인재'">
                                    <span>하이프로의 회원이 되신걸 환영합니다!</span><br/>
                                    '이력서' 및 'NCS 직무자가평가'도 등록 해 주세요!<br/>
                                    신뢰높은 프로젝트에 지원하실 수 있습니다!
                                </div>
								<div class="phone-info completion " v-else>
                                    <span>하이프로의 회원이 되신걸 환영합니다!</span><br/>
                                    성공적인 프로젝트를 위하여 인재평가와 검증 및 관리를 통하여<br/>
									검증된 IT전문인재들을 기업의 프로젝트와 연결해 드립니다.
                                </div>
                                <img class="completion" src="/images/mem/hipro_mem_img.png" alt="회원가입완료"/>
                            </div>
                            <div class="btn-login_completion" @click="pageMove()">로그인</div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
	data() {
		return {
			title : '',
		}
	},

	beforeMount() {
		if(this.$route.params) {
			this.title = this.$route.params.forwardData;
		}
	},

	methods : {
		pageMove() {
			this.$router.replace('MEM001M01');
		}
	}
}
</script>